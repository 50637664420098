@import "../variables";

.footer {
  background-color: #3f3f3f;
}

.footer__wrapper {
  padding-bottom: 20px;

  @media ($tablet) {
    padding-bottom: 45px;
  }

  @media ($desktop) {
    padding-bottom: 35px;
  }
}

.footer__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media ($tablet) {
    align-items: flex-end;
    margin-bottom: 85px;
  }

  @media ($desktop) {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.footer__logo {
  @media ($desktop) {
    margin-top: 38px;
  }
}

.footer__copy {
  margin: 10px 0 0;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  letter-spacing: -0.36px;
}

.footer__sk {
  margin-right: -15px;

  @media ($desktop) {
    margin-right: 0;
    margin-left: 47px;
  }
}

.footer__sk-img {
  display: block;
}

.footer__contacts {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ($tablet) {
    width: auto;
    margin-top: 0;
    align-items: flex-end;
  }

  @media ($desktop) {
    margin-left: auto;
    margin-top: 35px;
  }
}

.footer__phone {
  font-size: 24px;
  line-height: 19px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  margin-bottom: 7px;

  &:hover {
    text-decoration: underline;
  }
}

.footer__email {
  font-size: 18px;
  line-height: 19px;
  font-weight: 600;
  color: #fff;

  &:hover {
    text-decoration: none;
  }
}

.footer__nav {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    column-count: 2;
  }

  @media ($desktop) {
    width: 590px;
    column-gap: 0;
    margin-left: 400px;
    margin-top: -77px;
  }
}

.footer__nav-item {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    text-align: left;
  }
}

.footer__nav-link {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.44px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media ($tablet) {
    font-size: 24px;
    letter-spacing: -0.53px;
  }
}

.footer__bottom {
  padding: 30px 0;
  background-color: #2e2e2e;
  text-align: center;

  @media ($tablet) {
    padding: 23px 0;
    text-align: left;

    .container {
      display: flex;
      justify-content: space-between;
    }
  }
}

.footer__policy {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  opacity: .5;
  letter-spacing: -0.36px;

  &:hover {
    text-decoration: none;
  }
}

.footer__dev {
  margin: 30px 0 0;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  opacity: .5;
  letter-spacing: -0.36px;

  @media ($tablet) {
    margin-top: 0;
  }
}

.footer__dev-link {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  letter-spacing: -0.36px;

  &:hover {
    text-decoration: none;
  }
}

.footer__content {
  padding: 55px 0 15px;

  @media ($tablet) {
    padding: 50px 0 70px;
  }

  @media ($desktop) {
    padding: 100px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.footer__prototype {
  margin-bottom: 50px;
  color: #fff;

  @media ($tablet) {
    margin-bottom: 70px;
  }

  @media ($desktop) {
    width: 570px;
    margin-bottom: 0;
    padding-top: 44px;
  }
}

.footer__img {
  margin-bottom: 15px;
  display: block;

  img {
    display: block;
    margin: 0 auto;

    @media ($tablet) {
      margin: 0;
    }
  }
}

.footer__prototype-text {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.36px;
  text-align: center;
  margin: 0 0 30px;

  @media ($tablet) {
    text-align: left;
  }

  @media ($desktop) {
    width: 490px;
  }
}

.footer__prototype-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-top: 70px;
    column-count: 2;
    column-gap: 0;
  }

  @media ($desktop) {
    margin-top: 45px;
  }
}

.footer__prototype-item {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    width: 100%;
    text-align: left;
  }
}

.footer__prototype-link {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.44px;
  color: #fff;
  text-decoration: none;

  @media ($tablet) {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: -0.53px;
  }
}

.footer__form {
  color: #fff;

  @media ($desktop) {
    width: 570px;
  }
}

.footer__form-title {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    text-align: left;
    margin-bottom: 20px;
  }
}

.footer__form-wrapper {
  @media ($tablet) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    .footer__form-item {
      width: 48%;
      margin-bottom: 0;
    }

    .label--check {
      width: 200px;
    }
  }
}

.footer__form-item {
  margin-bottom: 15px;

  @media ($tablet) {
    margin-bottom: 30px;
  }

  .input--default,
  .textarea {
    width: 100%;
  }

  .textarea {
    height: 120px;
  }

  .label {
    a {
      color: #bf4e30;
      text-decoration: none;
    }
  }

  .btn {
    width: 100%;
    height: 65px;
  }
}