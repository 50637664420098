@import "variables";

.body {
  background-color: #fff;
  font-family: 'TT Commons', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  min-width: 320px;

  &--main {
    background-color: #f9f9f9;
  }
}

.container {
  padding: 0 15px;
  box-sizing: border-box;

  @media ($tablet) {
    padding: 0 45px;
  }

  @media ($desktop) {
    width: 1260px;
    margin: 0 auto;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}