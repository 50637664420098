@import "../variables";

.about {
  padding: 70px 0 40px;

  @media ($tablet) {
    padding: 30px 0 20px;
  }

  @media ($desktop) {
    padding: 70px 0 50px;
  }
}

.about__title {
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;

  @media ($tablet) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }
}

.about__text {
  line-height: 23px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 30px;
  }
}

.about__list {
  margin: 30px 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 50px 0 0;
  }

  @media ($desktop) {
    justify-content: space-around;
  }
}

.about__item {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    width: 324px;
    margin-bottom: 50px;
  }
}

.about__img-wrapper {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 10px;

  @media ($tablet) {
    margin-bottom: 30px;
  }
}

.about__name {
  margin: 0;
  font-size: 24px;
  line-height: 38px;

  @media ($tablet) {
    font-size: 28px;
  }
}

.about__position {
  margin: 0 0 10px;
  line-height: 23px;
  opacity: .5;
}

.about__desc {
  line-height: 23px;
  margin: 0;
}