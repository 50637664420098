@import "../variables";

.breadcrumbs {
  margin: 0 0 15px;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ($tablet) {
    justify-content: flex-start;
    margin-bottom: 30px;
  }
}

.breadcrumbs__item {
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    content: '';
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background-color: #5dd39e;
    margin: -3px 10px 0;
  }

  &:last-child .breadcrumbs__link {
    pointer-events: none;
    color: #2c2f34;
  }
}

.breadcrumbs__link {
  font-size: 14px;
  line-height: 24px;
  color: #9c9c9c;
  text-decoration: none;
  font-weight: 500;

  @media ($tablet) {
    font-size: 16px;
  }
}