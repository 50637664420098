@import "../variables";

.blockquote {
  margin: 30px -15px;
  padding: 30px 15px;
  background-color: #f9f9f9;

  @media ($tablet) {
    position: relative;
    padding-left: 100px;
    margin: 30px 0;
  }
}

.blockquote__svg {
  width: 40px;
  height: 37px;
  margin-bottom: 12px;

  @media ($tablet) {
    margin-bottom: 0;
    position: absolute;
    left: 30px;
    top: 30px;
  }
}

.blockquote__content {
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 25px;

  @media ($tablet) {
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 30px;
  }
}

.blockquote__name {
  margin: 0 0 5px;
  line-height: 23px;
  font-weight: 700;

  @media ($tablet) {
    font-size: 20px;
    line-height: 20px;
  }
}

.blockquote__type {
  margin: 0;
  line-height: 23px;
  opacity: .5;

  @media ($tablet) {
    font-size: 18px;
    line-height: 20px;
  }
}
