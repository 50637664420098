@import "../variables";

.production {
  padding: 70px 0 65px;

  @media ($desktop) {
    padding: 115px 0;

    .container {
      position: relative;
    }
  }
}

.production__title {
  text-align: center;

  @media ($tablet) {
    text-align: left;
  }
}

.production__text {
  font-size: 18px;
  line-height: 31px;
  margin: 15px 0 30px;

  @media ($tablet) {
    margin: 30px 0 50px;
  }
}

.production__about {
  margin-bottom: 60px;

  @media ($tablet) {
    display: none;
  }

  @media ($desktop) {
    display: block;
    position: absolute;
    top: 30px;
    right: 45px;
    margin-bottom: 0;
  }
}

.production__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 27px;
  color: #000;
  text-decoration: none;

  &:hover {
    .production__link-svg {
      transform: translateX(10px);
    }
  }

  @media ($desktop) {
    font-size: 24px;
    line-height: 43px;
  }
}

.production__link-svg {
  width: 24px;
  height: 24px;
  fill: #bf4e30;
  margin-left: 10px;
  transition: all .2s;

  @media ($desktop) {
    margin-left: 15px;
  }
}

.production__slider {
  margin-top: 40px;
  margin-right: -15px;

  @media ($tablet) {
    margin-right: -45px;
  }

  @media ($desktop) {
    margin-right: 0;
  }
}

.production__item {
  img {
    display: block;
    width: 100%;
  }
}

.production__controls {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  position: relative;

  @media ($tablet) {
    justify-content: flex-start;
    margin-left: -25px;
    margin-top: 25px;
  }
}

.production__prev,
.production__next {
  position: static;
  background-image: none;
  width: 36px;
  height: 36px;
  margin: 0 15px;
  border-radius: 36px;
  transition: all .2s;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.production__prev-svg,
.production__next-svg {
  width: 36px;
  height: 36px;
  fill: #bf4e30;
}