@import "../variables";

.partners {
  padding: 50px 0 35px;
  background-color: #4e95a6;
  color: #fff;

  @media ($tablet) {
    padding-top: 70px;
  }

  @media ($desktop) {
    padding: 60px 0 70px;
  }
}

.partners__title {
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255,255,255,.3);
  margin-bottom: 15px;

  @media ($tablet) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  @media ($desktop) {
    margin-bottom: 80px;
  }
}

.partners__subtitle {
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 15px;

  @media ($tablet) {
    display: none;
  }
}

.partners__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media ($desktop) {
    justify-content: center;
  }
}

.partners__item {
  height: 120px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ($tablet) {
    width: 206px;
    height: 145px;
  }

  @media ($desktop) {
    width: 270px;
    height: 190px;
    margin: 0 11px;
  }

  &--last {
    display: none;

    @media ($tablet) {
      display: flex;
      font-size: 18px;
      line-height: 25px;
    }

    @media ($desktop) {
      order: -1;
    }
  }
}