@import "../variables";

.services__title {
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;

  @media ($tablet) {
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
}

.services__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-right: -30px;
  }

  @media ($desktop) {
    justify-content: flex-start;
  }
}

.services__item {
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  margin-bottom: 30px;

  @media ($tablet) {
    width: 324px;
    min-height: 223px;
    transition: all .2s;
    margin-right: 30px;

    &:hover {
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-10px);
    }
  }

  @media ($desktop) {
    width: 270px;
  }
}

.services__svg {
  margin-bottom: 14px;

  &--therm {
    width: 24px;
    height: 24px;
  }

  &--sinus {
    width: 25px;
    height: 24px;
  }

  &--sett {
    width: 23px;
    height: 23px;
  }

  &--lightning {
    width: 16px;
    height: 24px;
  }

  &--ear {
    width: 24px;
    height: 24px;
  }

  &--vortex {
    width: 24px;
    height: 16px;
  }

  &--search {
    width: 24px;
    height: 22px;
  }

  &--molecule {
    width: 25px;
    height: 26px;
  }

  &--solid {
    width: 24px;
    height: 12px;
  }

  &--coffee {
    width: 26px;
    height: 17px;
  }

  &--eye {
    width: 24px;
    height: 15px;
  }

  &--rad {
    width: 21px;
    height: 22px;
  }

  &--pipette {
    width: 18px;
    height: 24px;
  }
}

.services__name {
  margin: 0;
  font-size: 22px;
  line-height: 30px;
}

.services__link {
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: 230px;
  box-sizing: border-box;
}

.services__text {
  margin: auto 0 0;
  font-size: 14px;
  line-height: 20px;
}

.services__buttons {
  text-align: center;

  @media ($tablet) {
    display: none;
  }
}

.services__more {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  color: rgba(0,0,0,.5);
  margin-bottom: 40px;
  padding: 15px 20px 10px;
  border-radius: 36px;
  transition: all .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.services__more-svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.services__button {
  height: 65px;
}