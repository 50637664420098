@import "../variables";

.input {
  border: none;
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-family: 'TT Commons', 'Arial', sans-serif;

  &--default {
    height: 65px;
    background-color: #fff;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
    padding: 0 20px;
    font-size: 20px;
    line-height: 30px;
  }

  &--check:checked + .label::before {
    background: #bf4e30 url('../img/svg/icon-tick.svg') 50% 50% no-repeat;
    border: transparent;
  }

  &--physics:checked + .label::before {
    background: #5dd39e url('../img/svg/icon-tick.svg') 50% 50% no-repeat;
    border: transparent;
  }
}