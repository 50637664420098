@import "../variables";

.equipment {
  padding: 30px 0 70px;
}

.equipment__title {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 30px;

  @media ($tablet) {
    padding-bottom: 20px;
  }

  @media ($desktop) {
    padding-bottom: 5px;
    margin-bottom: 50px;
  }
}

.equipment__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ($desktop) {
    justify-content: flex-start;
  }
}

.equipment__item {
  width: 130px;
  margin-bottom: 30px;

  @media ($tablet) {
    width: 324px;
  }

  @media ($desktop) {
    width: 270px;
    margin-right: 30px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.equipment__link {
  text-decoration: none;
  color: #000;
}

.equipment__img {
  display: block;
  margin-bottom: 15px;

  @media ($desktop) {
    width: 100%;
  }
}

.equipment__name {
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;

  @media ($tablet) {
    font-size: 16px;
    line-height: 23px;
    width: 200px;
  }
}

.equipment__more {
  text-align: center;
}

.equipment__more-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  color: rgba(0,0,0,.5);
  padding: 15px 20px 10px;
  border-radius: 36px;
  transition: all .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media ($tablet) {
    font-size: 24px;
    line-height: 43px;
  }
}

.equipment__more-svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}