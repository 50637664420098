@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/ttcommons-bold.woff2') format('woff2'),
  url('../fonts/ttcommons-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/ttcommons-demibold.woff2') format('woff2'),
  url('../fonts/ttcommons-demibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/ttcommons-medium.woff2') format('woff2'),
  url('../fonts/ttcommons-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Commons';
  src: url('../fonts/ttcommons-regular.woff2') format('woff2'),
  url('../fonts/ttcommons-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}