@import "../variables";

.contacts {
  padding: 50px 0 20px;

  @media ($tablet) {
    padding: 0;
    position: relative;
  }

  @media ($desktop) {
    .container {
      position: relative;
    }
  }

  &--page {
    padding: 30px 0 0;

    .contacts__title {
      font-size: 32px;
      line-height: 43px;

      @media ($tablet) {
        text-align: center;
        font-size: 60px;
        line-height: 80px;
        letter-spacing: -1px;
      }

      @media ($desktop) {
        font-size: 90px;
        line-height: 119px;
        margin-bottom: 25px;
      }
    }

    .contacts__content {
      background-color: #fff;
      padding: 50px 15px 20px;
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.08);

      @media ($tablet) {
        position: static;
        transform: none;
        width: 100%;
        padding: 40px 70px 30px;
      }

      @media ($desktop) {
        width: 50%;
        flex-shrink: 0;
        padding: 40px 0 30px;
        display: flex;
        justify-content: flex-end;

        .container {
          width: 630px;
          margin: 0;
        }
      }
    }
  }

  &--services {
    background-color: #fff;
  }
}

.contacts__content {
  @media ($tablet) {
    position: absolute;
    bottom: 70px;
    background-color: #fff;
    width: 618px;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 40px 40px 10px;
  }

  @media ($desktop) {
    right: 40px;
    left: auto;
    transform: none;
    bottom: 60px;
  }
}

.contacts__title {
  text-align: center;
  margin-bottom: 15px;

  @media ($tablet) {
    margin-bottom: 30px;
    text-align: left;
  }

  @media ($desktop) {
    font-size: 40px;
    line-height: 50px;
  }
}

.contacts__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;

  @media ($tablet) {
    text-align: left;
    font-weight: 500;
  }
}

.contacts__item {
  margin-bottom: 30px;
}

.contacts__name {
  line-height: 24px;
  color: #272727;
  font-weight: 500;
  opacity: .5;
  margin: 0 0 10px;

  @media ($tablet) {
    margin-bottom: 5px;
  }
}

.contacts__phone {
  font-size: 24px;
  line-height: 33px;
  color: #272727;
  text-decoration: none;

  @media ($tablet) {
    font-size: 40px;
    line-height: 53px;
  }
}

.contacts__email {
  font-size: 24px;
  line-height: 33px;
  color: #272727;
  text-decoration: none;

  @media ($tablet) {
    font-size: 28px;
    line-height: 37px;
  }
}

.contacts__text {
  font-size: 24px;
  line-height: 33px;
  color: #272727;
  margin: 0;

  @media ($tablet) {
    font-size: 28px;
    line-height: 39px;
  }
}

.contacts__br {
  @media ($tablet) {
    display: none;
  }
}

.contacts__wrapper {
  @media ($desktop) {
    display: flex;
  }
}