@import "../variables";

.skolkovo {
  padding: 50px 0;
  background-color: #fff;

  @media ($desktop) {
    background-color: #f9f9f9;
    padding: 0;

    .container {
      width: 1170px;
      padding: 50px;
      background-color: #fff;
      margin-bottom: 100px;
    }
  }
}

.skolkovo__logo {
  text-align: center;
  margin-bottom: 36px;

  img {
    width: 112px;
  }
}

.skolkovo__title {
  text-align: center;

  @media ($tablet) {
    font-size: 32px;
  }

  @media ($desktop) {
    font-size: 40px;
  }
}

.skolkovo__text {
  line-height: 23px;

  @media ($tablet) {
    font-size: 18px;
    line-height: 30px;
  }
}

.skolkovo__buttons {
  text-align: center;
  margin-top: 30px;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media ($desktop) {
    margin-top: 50px;
  }
}

.skolkovo__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  line-height: 33px;
  margin-bottom: 30px;
  text-decoration: none;

  &:hover {
    .skolkovo__button-svg {
      transform: translateX(10px);
    }
  }

  @media ($tablet) {
    width: 50%;
    margin-bottom: 0;
    justify-content: flex-start;
    font-size: 24px;
  }
}

.skolkovo__button {
  @media ($tablet) {
    width: 47%;
  }

  @media ($desktop) {
    width: 31%;
  }
}

.skolkovo__button-svg {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  fill: #5dd39e;
  transition: all .2s;
}