@import "../variables";

.article {
  margin: 30px 0 60px;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;

    &--left {
      flex-direction: row-reverse;
    }

    .title {
      text-align: left;
    }
  }
}

.article__img {
  margin-bottom: 20px;

  @media ($tablet) {
    width: 47%;
  }
}

.article__content {
  @media ($tablet) {
    width: 47%;
  }

  @media ($desktop) {
    font-size: 18px;
    line-height: 27px;

    .btn {
      width: 170px;
    }
  }
}