@import "../variables";

.physics {
  padding-top: 30px;

  @media ($tablet) {
    padding-top: 50px;
    padding-bottom: 110px;
  }

  @media ($desktop) {
    padding-top: 64px;
    padding-bottom: 40px;
    position: relative;

    &::after {
      content: url('../img/physics-bg.png');
      position: absolute;
      top: -80px;
      right: 0;
    }
  }
}

.physics__title {
  text-align: center;

  @media ($tablet) {
    margin-bottom: 40px;
  }

  @media ($desktop) {
    padding: 0 150px;
    margin-bottom: 30px;
  }
}

.physics__subtitle {
  margin: 30px 0;
  font-size: 18px;
  line-height: 25px;
  color: #58585a;
  text-align: center;

  @media ($tablet) {
    padding: 0 100px;
  }

  @media ($desktop) {
    padding: 0 350px;
  }
}