@import "../variables";

.services-page {
  padding: 30px 0 40px;

  .table {
    background-color: #efefef;
  }

  @media ($desktop) {
    .table-wrapper {
      margin: 0 0 50px;
    }
  }
}

.services-page__title {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 10px;

  @media ($tablet) {
    padding-bottom: 20px;
  }

  @media ($desktop) {
    padding-bottom: 5px;
    margin-bottom: 50px;
  }
}

.services-page__wrapper {
  @media ($desktop) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.services-page__list {
  margin: 0 -15px 35px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  overflow-x: auto;

  @media ($tablet) {
    margin-right: -45px;
  }

  @media ($desktop) {
    width: 270px;
    display: block;
  }
}

.services-page__item {
  display: flex;
  justify-content: center;
  align-items: center;

  @media ($tablet) {
    margin-right: 10px;
  }
}

.services-page__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  color: #000;
  opacity: .6;
  padding: 20px;
  width: 210px;
  box-sizing: border-box;
  transition: opacity .2s;

  &:hover {
    opacity: 1;
  }

  @media ($tablet) {
    width: 270px;
    font-size: 20px;
    line-height: 28px;
  }

  &--active {
    opacity: 1;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.08);
  }
}

.services-page__content {
  @media ($desktop) {
    width: 870px;
  }
}