@import "../variables";

.video {
  margin: 30px 0;

  iframe {
    width: 100%;
    height: 165px;

    @media ($tablet) {
      height: 385px;
    }

    @media ($desktop) {
      height: 650px;
    }
  }
}