@import "../variables";

.pagination {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__item {
  &--home,
  &--end {
    display: none;

    @media ($tablet) {
      display: block;

      .pagination__link {
        width: auto;

        &--home {
          margin-right: 15px;
        }

        &--end {
          margin-left: 15px;
        }

        &:hover {
          background-color: transparent;
          color: #0a0a0a;
        }
      }
    }
  }
}

.pagination__link {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.3);
  transition: all .2s;
  text-decoration: none;

  &--active {
    color: #0a0a0a;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);

    .pagination__prev-svg,
    .pagination__next-svg {
      fill: #5beb82;
    }
  }
}

.pagination__prev-svg,
.pagination__next-svg {
  width: 28px;
  height: 28px;
  fill: #5dd39e;
  transition: fill .2s;
}