@import "../variables";

.directions {
  padding: 70px 0 40px;

  @media ($tablet) {
    padding: 70px 0;
  }

  @media ($desktop) {
    padding: 120px 0 90px;
  }
}

.directions__title {
  text-align: center;
  margin-bottom: 44px;

  @media ($tablet) {
    margin-bottom: 40px;
  }

  @media ($desktop) {
    text-align: left;
    margin-bottom: 10px;
  }
}

.directions__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  counter-reset: directions;

  @media ($tablet) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-right: -30px;
  }

  @media ($desktop) {
    justify-content: flex-start;
  }
}

.directions__item {
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);

  @media ($tablet) {
    width: 324px;
    min-height: 275px;
    transition: all .2s;
    margin-right: 30px;

    &:hover {
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-10px);
    }
  }

  @media ($desktop) {
    width: 370px;
  }
}

.directions__link {
  color: #000;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  min-height: 308px;
  box-sizing: border-box;
  padding: 30px;

  &::before {
    counter-increment: directions;
    content: counter(directions)'.';
    display: block;
    font-size: 50px;
    line-height: 33px;
    font-weight: 500;
    margin-bottom: 30px;
  }

  @media ($tablet) {
    min-height: 275px;
  }
}

.directions__name {
  font-size: 24px;
  line-height: 33px;
  margin: 0;
}

.directions__text {
  margin-top: auto;
  line-height: 23px;
}