@import "../variables";

.examples {
  padding: 70px 0 65px;
  background-color: #bf4e30;
  color: #fff;
  text-align: center;
}

.examples__top {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.examples__title {
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 0;
  }
}

.examples__link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
  text-decoration: none;

  &:hover {
    .examples__link-svg {
      transform: translateX(10px);
    }
  }

  @media ($tablet) {
    font-size: 24px;
    line-height: 43px;
  }
}

.examples__link-svg {
  width: 24px;
  height: 24px;
  fill: #fff;
  margin-left: 10px;
  transition: all .2s;

  @media ($tablet) {
    margin-left: 15px;
  }
}

.examples__slider {
  margin-top: 40px;
  margin-right: -15px;

  @media ($tablet) {
    margin-top: 65px;
    margin-right: -45px;
  }

  @media ($desktop) {
    margin-top: 30px;
    margin-right: 0;
  }
}

.examples__item {
  img {
    display: block;
    width: 100%;
  }
}

.examples__controls {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  position: relative;

  @media ($tablet) {
    margin-top: 35px;
    justify-content: flex-start;
    margin-left: -25px;
  }
}

.examples__prev,
.examples__next {
  position: static;
  background-image: none;
  width: 36px;
  height: 36px;
  margin: 0 15px;
  border-radius: 36px;
  transition: all .2s;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.examples__prev-svg,
.examples__next-svg {
  width: 36px;
  height: 36px;
  fill: #fff;
}