@import "../variables";

.btn {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: 400;
  transition: all .2s;

  @media ($tablet) {
    font-size: 20px;
  }

  &--default {
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
    background-color: #5dd39e;
    color: #fff;
    height: 65px;

    &:hover {
      box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03), 0 4px 14px 0 rgba(0, 0, 0, 0.08);
      background-color: #5beb82;
    }

    &:active {
      background-color: #47a178;
      box-shadow: none;
    }
  }

  &--secondary {
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
    background-color: #ffffff;
    color: #bf4e30;

    &:hover {
      background-color: darken(#fff, 10%);
    }

    &:active {
      background-color: darken(#fff, 20%);
    }
  }

  &--proto {
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
    background-color: #bf4e30;
    color: #fff;

    &:hover {
      background-color: #d6692b;
    }

    &:active {
      background-color: #d6372b;
    }
  }
}