@import "../variables";

.projects {
  padding: 30px 0 70px;
}

.projects__title {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 30px;

  @media ($tablet) {
    padding-bottom: 20px;
  }

  @media ($desktop) {
    padding-bottom: 5px;
    margin-bottom: 50px;
  }
}

.projects__list {
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.projects__item {
  position: relative;
  margin-bottom: 15px;

  @media ($tablet) {
    margin-bottom: 30px;

    &--wide {
      width: 100%;

      .projects__img {
        height: 310px;
        width: 100%;
        object-fit: cover;
      }

      .projects__content {
        display: flex;
        justify-content: space-between;
      }

      .projects__name {
        width: auto;
        margin-bottom: 0;
      }
    }
  }

  @media ($desktop) {
    width: 370px;

    &--wide {
      width: 770px;

      .projects__img {
        height: 352px;
      }

      .projects__content {
        padding: 50px 30px 30px;
      }

      .projects__link {
        font-size: 20px;
      }
    }
  }
}

.projects__img {
  display: block;

  @media ($desktop) {
    width: 100%;
  }
}

.projects__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 40px 15px 15px;
  z-index: 1;
  color: #fff;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));

  @media ($tablet) {
    padding: 50px 30px 30px;
  }

  @media ($desktop) {
    padding: 50px 15px 20px;
  }
}

.projects__name {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin: 0 0 15px;
  width: 240px;

  @media ($tablet) {
    font-size: 24px;
    line-height: 32px;
  }

  @media ($desktop) {
    width: auto;
  }
}

.projects__link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 19px;

  @media ($tablet) {
    font-weight: 500;
  }
}

.projects__link-svg {
  width: 20px;
  height: 18px;
  fill: #fff;
  margin-left: 12px;
}

.projects__more {
  text-align: center;
}

.projects__more-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  color: rgba(0,0,0,.5);
  padding: 15px 20px 10px;
  border-radius: 36px;
  transition: all .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media ($tablet) {
    font-size: 24px;
    line-height: 43px;
  }
}

.projects__more-svg {
  width: 24px;
  height: 24px;
  margin-left: 16px;
}

.projects__item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}