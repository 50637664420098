@import "../variables";

.popup {
  display: none;
}

.popup-callback {
  background-color: #f9f9f9;
  box-sizing: border-box;
  border: solid 12px #5dd39e;
  padding: 35px 30px 15px;

  @media ($tablet) {
    width: 450px;
    padding: 45px 55px 15px;
  }
}

.popup-callback__form {
  background-color: #fff;
}

.popup-callback__form-title {
  font-size: 20px;
  font-weight: 900;
  line-height: 27px;
  margin: 0 0 3px;
  text-align: center;

  @media ($tablet) {
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 6px;
    text-align: left;
  }
}

.popup-callback__form-subtitle {
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 20px;
  width: 220px;

  @media ($tablet) {
    font-size: 16px;
    line-height: 23px;
    margin: 0 0 30px;
    text-align: left;
    width: auto;
  }
}

.popup-callback__form-item {
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 30px;
  }

  .input--default {
    width: 100%;

    @media ($tablet) {
      height: 70px;
    }
  }

  .btn {
    height: 52px;
    font-size: 10px;
    width: 100%;
    font-weight: 700;

    @media ($tablet) {
      height: 70px;
      font-size: 14px;
    }
  }

  .label--check {
    line-height: 14px;
  }

  a {
    color: #000;
  }
}