@import "../variables";

.main {
  padding-bottom: 40px;

  @media ($desktop) {
    padding-bottom: 140px;
  }
}

.main__title {
  text-align: center;
  margin: 30px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;

  @media ($tablet) {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.8px;
    padding: 0 110px 30px;
  }

  @media ($desktop) {
    font-size: 60px;
    line-height: 106px;
    letter-spacing: 0;
    padding: 0 0 8px;
  }
}

.main__wrapper {
  @media ($desktop) {
    display: flex;
    justify-content: space-between;
  }
}

.main__item {
  box-sizing: border-box;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  transition: all .2s;

  &:hover {
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03), 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
  }

  img {
    display: block;
    width: 100%;
  }

  @media ($desktop) {
    width: 570px;
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &--physics {
    &::before {
      background-color: rgba(78, 149, 166, 0.6);
    }
  }

  &--prototyping {
    &::before {
      background-color: rgba(191, 78, 48, 0.6);
    }
  }
}

.main__item-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 255px;
  padding: 20px 15px 15px;
  box-sizing: border-box;

  @media ($tablet) {
    min-height: 470px;
    align-items: flex-start;
    padding: 50px;
  }
}

.main__item-title {
  font-size: 24px;
  line-height: 32px;
  color: #fff;
  font-weight: 500;
  margin: 0 0 12px;

  @media ($tablet) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
  }
}

.main__text {
  line-height: 23px;
  color: #fff;

  @media ($tablet) {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
}

.main__btn {
  width: 100%;
  height: 65px;
  margin-top: auto;

  @media ($tablet) {
    width: 270px;
  }
}

.main__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}