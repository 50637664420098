@import "../variables";

.table-wrapper {
  overflow-x: auto;
  margin: 30px -15px;

  @media ($tablet) {
    margin: 30px 0;
  }
}

.table {
  border-collapse: collapse;
  background-color: #f9f9f9;

  @media ($tablet) {
    width: 100%;
  }

  td,
  th {
    box-sizing: border-box;
    padding: 15px;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  th {
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    border: 1px solid #d8d8d8;
    vertical-align: top;
    min-width: 80px;

    &:first-child {
      min-width: 215px;
    }
  }

  td {
    font-size: 18px;
    border: 1px solid #d8d8d8;
    line-height: 22px;
  }
}