@import "../variables";

.prototype {
  margin-top: -50px;
  background-color: #bf4e30;
  color: #fff;
  padding: 120px 0 65px;

  @media ($tablet) {
    margin-top: -219px;
    padding-top: 306px;
    background: #bf4e30 url('../img/prototype-bg.svg') 100% 100% no-repeat;
  }

  @media ($desktop) {
    margin-top: -165px;
    padding-top: 179px;
  }
}

.prototype__item {
  text-align: center;

  @media ($tablet) {
    text-align: left;
  }
}

.prototype__title {
  font-size: 32px;
  line-height: 43px;
  font-weight: 500;
  margin: 0 0 10px;

  @media ($tablet) {
    font-size: 90px;
    line-height: 119px;
    letter-spacing: -2.25px;
  }
}

.prototype__subtitle {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 40px;
    line-height: 50px;
  }
}

.prototype__text {
  font-size: 19px;
  margin: 0 0 30px;

  @media ($tablet) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 50px;
  }

  @media ($desktop) {
    width: 490px;
  }
}

.prototype__button {
  .btn {
    height: 65px;

    @media ($tablet) {
      width: 270px;
    }
  }
}

.prototype__controls {
  margin-top: 65px;
  display: flex;
  justify-content: center;
  position: relative;

  @media ($tablet) {
    margin-top: 205px;
  }

  @media ($desktop) {
    margin-top: 77px;
    justify-content: flex-end;
  }
}

.prototype__prev,
.prototype__next {
  position: static;
  background-image: none;
  width: 36px;
  height: 36px;
  margin: 0 15px;
  border-radius: 36px;
  transition: all .2s;
  padding: 10px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.prototype__prev-svg,
.prototype__next-svg {
  width: 36px;
  height: 36px;
  fill: #fff;
}