@import "../variables";

.label {

  &--check {
    display: inline-block;
    position: relative;
    padding-left: 22px;
    font-size: 14px;
    line-height: 21px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      border: solid 1px #ebeaea;
      background-color: #ffffff;
      box-sizing: border-box;
    }
  }
}