@import "../variables";

.map {
  display: none;

  @media ($tablet) {
    display: block;
    width: 100%;
    height: 1140px;
  }

  @media ($desktop) {
    height: 740px;
  }

  &--contacts {
    display: block;
    height: 300px;

    @media ($tablet) {
      height: 568px;
    }
  }
}