@import "../variables";

.header {
  padding: 7px 0;

  @media ($tablet) {
    padding: 20px 0 0;
  }

  &--light {
    position: relative;
    z-index: 10;

    .header__logo-img {
      display: none;
    }

    .header__logo-light {
      display: block;
      width: 100%;
    }

    .header__button-svg {
      fill: #fff;
      stroke: #fff;
    }

    @media ($tablet) {
      .header__nav-logo-img {
        display: none;
      }

      .header__nav-logo-light {
        display: block;
      }

      .header__slogan,
      .header__phone,
      .header__email,
      .header__nav-link {
        color: #fff;
      }

      .header__nav-link:hover {
        color: #fff;
      }
    }
  }
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  width: 107px;

  @media ($tablet) {
    display: none;
  }
}

.header__logo-img {
  display: block;
  width: 100%;

  @media ($tablet) {
    width: 157px;
  }
}

.header__logo-light {
  display: none;
}

.header__button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: 36px;
  height: 36px;

  @media ($tablet) {
    display: none;
  }
}

.header__button-svg {
  width: 36px;
  height: 36px;
  fill: #58585a;
  stroke: #58585a;
}

.header__nav-wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 15px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 100;
  padding: 7px 15px 40px;
  flex-direction: column;
  box-shadow: 15px 0 15px 15px rgba(0, 0, 0, 0.8);

  &--open {
    display: flex;
  }

  @media ($tablet) {
    display: flex;
    position: static;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  @media ($desktop) {
    flex-wrap: nowrap;
  }
}

.header__nav-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  @media ($tablet) {
    margin-bottom: 30px;
  }

  @media ($desktop) {
    margin-bottom: 0;
  }
}

.header__nav-logo {
  width: 107px;

  @media ($tablet) {
    width: auto;
    text-decoration: none;
  }
}

.header__nav-logo-img {
  display: block;
  width: 100%;

  @media ($tablet) {
    width: 157px;
  }
}

.header__nav-logo-light {
  display: none;
}

.header__slogan {
  display: none;

  @media ($tablet) {
    display: block;
    width: 240px;
    line-height: 23px;
    letter-spacing: -0.32px;
    color: #58585a;
    margin: 15px 0 0;
  }

  @media ($desktop) {
    width: 190px;
  }
}

.header__nav-close {
  padding: 0;
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: none;

  @media ($tablet) {
    display: none;
  }
}

.header__nav {
  margin: 0 0 60px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    order: 3;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }

  @media ($desktop) {
    order: 2;
    width: auto;
    margin-top: 15px;
    max-width: 700px;
    flex-wrap: wrap;
  }

  &--inside {
    @media ($desktop) {
      max-width: none;
    }

    .header__nav-link {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: -0.36px;
    }

    .header__nav-item {
      @media ($desktop) {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.header__nav-item {
  text-align: center;
  margin-bottom: 30px;

  @media ($tablet) {
    text-align: left;
    margin-bottom: 0;
  }

  @media ($desktop) {
    margin-right: 70px;
  }
}

.header__nav-link {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.4px;
  color: #58585a;
  text-decoration: none;
  transition: color .2s;

  @media ($desktop) {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.36px;
  }

  &:hover {
    color: #000;
  }

  &--active {
    color: #000;
  }
}

.header__contacts {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ($tablet) {
    margin-top: 0;
    align-items: flex-end;
    padding-top: 8px;
  }

  @media ($desktop) {
    order: 3;
  }
}

.header__phone {
  font-size: 24px;
  line-height: 19px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 7px;
  }
}

.header__email {
  font-size: 18px;
  line-height: 19px;
  color: #000;
  font-weight: 600;
}