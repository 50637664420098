@import "../variables";

.gallery {
  @media ($tablet) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;

    &--half {
      .gallery__item {
        margin-right: 40px;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    &--three {
      .gallery__item {
        margin-right: 33px;

        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }

  @media ($desktop) {
    &--half {
      .gallery__item {
        margin-right: 70px;
      }
    }

    &--three {
      .gallery__item {
        margin-right: 58px;
      }
    }
  }
}

.gallery__item {
  margin-bottom: 15px;

  img {
    width: 100%;
  }

  @media ($tablet) {
    width: 30%;
    margin-bottom: 30px;

    &--half {
      width: 47%;
    }
  }
}