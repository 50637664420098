@import "../variables";

.textarea {
  border: none;
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
  resize: vertical;
  font-family: 'TT Commons', 'Arial', sans-serif;

  &--default {
    background-color: #fff;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.03);
    padding: 20px;
    font-size: 20px;
  }
}